import React from 'react'
import { graphql } from 'gatsby'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'

import Layout from '../components/Layout'

export default function WorkTemplate({ data: { work, relatedPosts } }) {
  // Pass empty array if there are no related blog posts.
  // The `relatedPosts` prop will be used as `<BlogPosts posts={props.relatedPosts} /> in the .mdx files
  const posts = relatedPosts ? relatedPosts.edges.map(({ node }) => node) : []

  const seo = {
    title: work.frontmatter.name,
    description: work.frontmatter.summary,
    slug: `work/${work.frontmatter.slug}`,
    image: work.frontmatter.seoImage
  }

  // The "wrapper" will be rendered like
  // <Layout>
  //   <article className="work">
  //     { mdx stuff }
  //   </article>
  // </Layout>
  return (
    <Layout
      colorScheme={work.frontmatter.pageColorScheme}
      layout={work.frontmatter.pageLayout}
      seo={seo}
    >
      <MDXRenderer
        components={{
          wrapper: ({ children }) => (
            <article className="work">{children}</article>
          ),
        }}
        relatedPosts={posts}
      >
        {work.code.body}
      </MDXRenderer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query WorkBySlug($slug: String!, $relatedPostsTags: [String]) {
    work: mdx(frontmatter: { slug: { eq: $slug } }, fileAbsolutePath: { regex: "/content/works/" }) {
      code {
        body
      }
      frontmatter {
        seoImage: image {
          ...seoImage
        }
        name
        pageLayout
        pageColorScheme
        slug
        summary
      }
    }
    relatedPosts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/content/posts//" }
        frontmatter: { tags: { in: $relatedPostsTags }, draft: { ne: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            cardSize
            slug
            summary
            title
            date(formatString: "YYYY-MM-DD")
            year: date(formatString: "YYYY")
            image {
              ...superheroImage
            }
          }
        }
      }
    }
  }
`

import React, { Component } from 'react'
import Link from './Link'

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showBurgerMenu: false
    }
  }

  toggleBurgerMenu() {
    this.setState({
      showBurgerMenu: !this.state.showBurgerMenu
    })
  }

  render() {
    const activeClass = 'active'

    return (
      <header>
        <nav>
          <Link className="home" to="/">
            <UnswornLogo />
          </Link>
          <ul className={this.state.showBurgerMenu ? 'active' : undefined}>
            <li>
              <Link to="/about" activeClassName={activeClass}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/work" activeClassName={activeClass}>
                Work
              </Link>
            </li>
            <li>
              <Link to="/blog" activeClassName={activeClass}>
                Blog
              </Link>
            </li>
            <li>
              <Link to="/contact" activeClassName={activeClass}>
                Contact
              </Link>
            </li>
            <li className="burgerbox" onClick={this.toggleBurgerMenu.bind(this)}>
              <span className="burger">☰</span>
            </li>
          </ul>
        </nav>
      </header>
    )
  }
}

const UnswornLogo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="116px"
    height="40px"
    viewBox="0 0 116 40"
    enableBackground="new 0 0 116 40"
  >
    <g>
      <path
        d="M39.024,29.909c-1.229,0-2.407-0.453-3.319-1.272l2.214-2.458c0.304,0.272,0.697,0.427,1.105,0.427
        c0.913,0,1.653-0.742,1.653-1.655c0-0.464,0-0.605-0.671-1.356l-0.008-0.008L35.6,18.612c-1.061-1.096-1.535-2.201-1.535-3.582
        c0-2.734,2.225-4.96,4.959-4.96c1.229,0,2.406,0.452,3.319,1.275l-2.212,2.457c-0.305-0.274-0.699-0.424-1.106-0.424
        c-0.911,0-1.652,0.742-1.652,1.653c0,0.44,0.094,0.765,0.62,1.299l0.058,0.062l4.422,5.002c0.981,1.096,1.513,1.987,1.513,3.557
        C43.984,27.687,41.758,29.909,39.024,29.909z"
      />
    </g>
    <g>
      <path
        d="M93.58,16.683c0-3.645-2.966-6.613-6.611-6.613h-6.614v19.839h3.307v-6.61h2.186l2.892,7.227l3.068-1.231l-2.562-6.409
      C91.771,21.953,93.58,19.529,93.58,16.683z M83.662,13.377h3.307c1.821,0,3.305,1.484,3.305,3.306c0,1.824-1.483,3.308-3.305,3.308
      h-3.307V13.377z"
      />
    </g>
    <g>
      <path
        d="M70.435,29.909c-3.647,0-6.612-2.966-6.612-6.61v-6.616c0-3.645,2.965-6.613,6.612-6.613c3.646,0,6.614,2.968,6.614,6.613
        v6.616C77.048,26.943,74.08,29.909,70.435,29.909z M70.435,13.377c-1.824,0-3.306,1.484-3.306,3.306v6.616
        c0,1.82,1.482,3.307,3.306,3.307c1.823,0,3.307-1.486,3.307-3.307v-6.616C73.741,14.861,72.258,13.377,70.435,13.377z"
      />
    </g>
    <g>
      <polygon
        points="20.838,29.909 17.533,29.909 17.533,10.07 20.869,10.07 27.452,23.236 27.452,10.07 30.758,10.07 30.758,29.909
        27.091,29.909 20.838,17.404 	"
      />
    </g>
    <g>
      <polygon
        points="100.193,29.909 96.885,29.909 96.885,10.07 100.223,10.07 106.806,23.236 106.806,10.07 110.113,10.07
        110.113,29.909 106.445,29.909 100.193,17.404 	"
      />
    </g>
    <g>
      <path
        d="M7.613,29.909C3.967,29.909,1,26.943,1,23.299V10.07h3.307v13.229c0,1.82,1.482,3.307,3.307,3.307
        c1.822,0,3.307-1.486,3.307-3.307V10.07h3.305v13.229C14.225,26.943,11.259,29.909,7.613,29.909z"
      />
    </g>
    <g>
      <polygon
        points="58.589,29.909 54.953,29.909 53.675,21.105 51.96,29.909 48.327,29.909 45.651,10.294 48.929,9.848 50.397,20.631
        52.459,10.07 55.416,10.07 56.934,20.532 58.89,9.775 62.142,10.367 	"
      />
    </g>
  </svg>
)

const UnswornIndustriesLogo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="250px"
    height="40px"
    viewBox="0 0 250 40"
    enableBackground="new 0 0 250 40"
  >
    <g>
      <path
        d="M39.024,29.909c-1.229,0-2.407-0.453-3.319-1.272l2.214-2.458c0.304,0.272,0.697,0.427,1.105,0.427
        c0.913,0,1.653-0.742,1.653-1.655c0-0.464,0-0.605-0.671-1.356l-0.008-0.008L35.6,18.612c-1.061-1.096-1.535-2.201-1.535-3.582
        c0-2.734,2.225-4.96,4.959-4.96c1.229,0,2.406,0.452,3.319,1.275l-2.212,2.457c-0.305-0.274-0.699-0.424-1.106-0.424
        c-0.911,0-1.652,0.742-1.652,1.653c0,0.44,0.094,0.765,0.62,1.299l0.058,0.062l4.422,5.002c0.981,1.096,1.513,1.987,1.513,3.557
        C43.984,27.687,41.758,29.909,39.024,29.909z"
      />
    </g>
    <g>
      <path
        d="M93.58,16.683c0-3.645-2.966-6.613-6.611-6.613h-6.614v19.839h3.307v-6.61h2.186l2.892,7.227l3.068-1.231l-2.562-6.409
      C91.771,21.953,93.58,19.529,93.58,16.683z M83.662,13.377h3.307c1.821,0,3.305,1.484,3.305,3.306c0,1.824-1.483,3.308-3.305,3.308
      h-3.307V13.377z"
      />
    </g>
    <g>
      <path
        d="M70.435,29.909c-3.647,0-6.612-2.966-6.612-6.61v-6.616c0-3.645,2.965-6.613,6.612-6.613c3.646,0,6.614,2.968,6.614,6.613
        v6.616C77.048,26.943,74.08,29.909,70.435,29.909z M70.435,13.377c-1.824,0-3.306,1.484-3.306,3.306v6.616
        c0,1.82,1.482,3.307,3.306,3.307c1.823,0,3.307-1.486,3.307-3.307v-6.616C73.741,14.861,72.258,13.377,70.435,13.377z"
      />
    </g>
    <g>
      <polygon
        points="20.838,29.909 17.533,29.909 17.533,10.07 20.869,10.07 27.452,23.236 27.452,10.07 30.758,10.07 30.758,29.909
        27.091,29.909 20.838,17.404 	"
      />
    </g>
    <g>
      <polygon
        points="100.193,29.909 96.885,29.909 96.885,10.07 100.223,10.07 106.806,23.236 106.806,10.07 110.113,10.07
        110.113,29.909 106.445,29.909 100.193,17.404 	"
      />
    </g>
    <g>
      <path
        d="M7.613,29.909C3.967,29.909,1,26.943,1,23.299V10.07h3.307v13.229c0,1.82,1.482,3.307,3.307,3.307
        c1.822,0,3.307-1.486,3.307-3.307V10.07h3.305v13.229C14.225,26.943,11.259,29.909,7.613,29.909z"
      />
    </g>
    <g>
      <polygon
        points="58.589,29.909 54.953,29.909 53.675,21.105 51.96,29.909 48.327,29.909 45.651,10.294 48.929,9.848 50.397,20.631
        52.459,10.07 55.416,10.07 56.934,20.532 58.89,9.775 62.142,10.367 	"
      />
    </g>
    <g>
      <polygon
        points="225.805,26.605 225.805,23.299 229.111,23.299 229.111,19.991 225.805,19.991 225.805,13.377 229.111,13.377
      229.111,10.07 222.499,10.07 222.499,29.909 232.419,29.909 232.419,26.605 "
      />
    </g>
    <g>
      <rect x="215.887" y="10.07" width="3.306" height="19.839" />
    </g>
    <g>
      <path
        d="M212.581,16.684c0-3.646-2.968-6.614-6.612-6.614h-6.615v19.839h3.308v-6.61h2.188l2.892,7.227l3.068-1.231l-2.565-6.409
      C210.771,21.953,212.581,19.529,212.581,16.684z M202.661,13.377h3.308c1.821,0,3.305,1.484,3.305,3.307
      c0,1.825-1.483,3.307-3.305,3.307h-3.308V13.377z"
      />
      <polygon
        points="196.049,10.07 186.129,10.07 186.129,13.377 189.435,13.377 189.435,29.909 192.741,29.909 192.741,13.377
      196.049,13.377 "
      />
    </g>
    <g>
      <path
        d="M181.17,29.909c-1.231,0-2.41-0.453-3.321-1.272l2.212-2.458c0.306,0.274,0.698,0.427,1.109,0.427
        c0.909,0,1.651-0.742,1.651-1.653c0-0.466,0-0.607-0.673-1.358l-0.006-0.008l-4.4-4.974c-1.06-1.095-1.533-2.2-1.533-3.582
        c0-2.734,2.222-4.96,4.96-4.96c1.227,0,2.405,0.452,3.317,1.276l-2.214,2.456c-0.303-0.274-0.697-0.424-1.103-0.424
        c-0.913,0-1.654,0.742-1.654,1.653c0,0.442,0.096,0.765,0.62,1.3l0.061,0.062l4.418,5c0.981,1.097,1.514,1.988,1.514,3.56
        C186.129,27.687,183.903,29.909,181.17,29.909z"
      />
    </g>
    <g>
      <path
        d="M239.031,29.909c-1.228,0-2.408-0.453-3.319-1.272l2.213-2.458c0.305,0.274,0.696,0.427,1.105,0.427
        c0.912,0,1.654-0.742,1.654-1.653c0-0.466,0-0.607-0.673-1.358l-0.005-0.008l-4.4-4.974c-1.062-1.095-1.536-2.2-1.536-3.582
        c0-2.734,2.226-4.96,4.96-4.96c1.229,0,2.409,0.452,3.32,1.276l-2.213,2.456c-0.303-0.274-0.695-0.424-1.106-0.424
        c-0.911,0-1.652,0.742-1.652,1.653c0,0.442,0.095,0.765,0.621,1.3l0.058,0.062l4.421,5c0.98,1.097,1.513,1.988,1.513,3.56
        C243.991,27.687,241.766,29.909,239.031,29.909z"
      />
    </g>
    <g>
      <path
        d="M166.29,29.909c-3.646,0-6.613-2.966-6.613-6.61V10.07h3.307v13.229c0,1.82,1.482,3.307,3.306,3.307
        c1.823,0,3.306-1.486,3.306-3.307V10.07h3.308v13.229C172.903,26.943,169.935,29.909,166.29,29.909z"
      />
    </g>
    <g>
      <path
        d="M149.756,29.909h-6.612V10.07h6.612c3.646,0,6.615,2.968,6.615,6.614v6.615C156.372,26.943,153.402,29.909,149.756,29.909z
         M146.452,26.605h3.305c1.825,0,3.307-1.486,3.307-3.307v-6.615c0-1.823-1.482-3.307-3.307-3.307h-3.305v9.921V26.605z"
      />
    </g>
    <g>
      <polygon
        points="129.919,29.909 126.612,29.909 126.612,10.07 129.949,10.07 136.532,23.236 136.532,10.07 139.838,10.07
        139.838,29.909 136.17,29.909 129.919,17.404 	"
      />
    </g>
    <g>
      <rect x="120" y="10.07" width="3.308" height="19.839" />
    </g>
  </svg>
)

import path from 'path'
import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import SchemaOrg from './SchemaOrg';


// Get an image url from an image object
const getImageSrc = image => {
  if (typeof image === 'string') {
    return image
  }
  if (image && image.childImageSharp) {
    if (image.childImageSharp.fluid) {
      return image.childImageSharp.fluid.src
    } else if (image.childImageSharp.resize) {
      return image.childImageSharp.resize.src
    }
  }
  console.warn('SEO: no image src found', image)
  return null
}

const SEO = props => {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              author
              description
              canonicalUrl
              image
              organization {
                name
                url
                # logo
              }
              social {
                twitter
              }
              title
            }
          }
        }
      `}
      render={({ site: { siteMetadata: meta } }) => {
        const { isBlogPost } = props
        const title = props.title || meta.title
        const description = props.description || meta.description
        const author = meta.author
        const datePublished = isBlogPost ? props.datePublished : false;

        const image = props.image
          ? `${meta.canonicalUrl}${getImageSrc(props.image)}`
          :  meta.image

        const url = props.slug
          ? `${meta.canonicalUrl}/${props.slug}`
          : meta.canonicalUrl

        return (
          <React.Fragment>
            <Helmet>
              {/* General tags */}
              <title>{`${title} · Unsworn`}</title>
              <meta name="description" content={description} />
              <meta name="image" content={image} />

              {/* OpenGraph tags */}
              <meta property="og:url" content={url} />
              {isBlogPost ? (
                <meta property="og:type" content="article" />
              ) : null}
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content={image} />

              {/* Twitter Card tags */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:creator" content={meta.social.twitter} />
              <meta name="twitter:title" content={title} />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:image" content={image} />
            </Helmet>
            <SchemaOrg
              isBlogPost={isBlogPost}
              url={url}
              title={title}
              image={image}
              description={description}
              datePublished={datePublished}
              canonicalUrl={meta.canonicalUrl}
              author={author}
              organization={meta.organization}
              defaultTitle={title}
            />
          </React.Fragment>
        )
      }}
    />
  )
}

SEO.propTypes = {
  datePublished: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string, // src 
    PropTypes.shape({
      childImageSharp: PropTypes.any, // fluid | fixed | resize
    }),
  ]),
  isBlogPost: PropTypes.bool,
  slug: PropTypes.string,
  title: PropTypes.string,
}

export default SEO

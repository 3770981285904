import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Footer from './Footer'
import Header from './Header'
import SEO from './SEO'

// Global styles
import 'typeface-roboto'
import 'typeface-paytone-one'
import '../style/main.less'

// This is used to wrap all pages with a header etc
const Layout = ({ children, colorScheme = 'blanc', layout = 'basic', seo }) => {
  const siteTitle = 'Unsworn'

  // TODO: Look at jlengstorf to make a proper SEO component
  return (
    <div className="root">
      <SEO {...seo} />
      <Helmet>
        <body className={`colorscheme-${colorScheme} layout-${layout}`} />
      </Helmet>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  colorScheme: PropTypes.string,
  layout: PropTypes.string,
  seo: PropTypes.object,
}

export default Layout

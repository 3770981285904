import React, { Component } from 'react'
import { Link } from 'gatsby'

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <p>
      <Link to="/">Unsworn</Link> · &nbsp;<a href="mailto:hello@unsworn.org">hello@unsworn.org</a> · &nbsp; Norra Grängesbergsgatan 20, 21450 Malmö, Sweden
        </p>
      </footer>
    )
  }
}
